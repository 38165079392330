<template>
    <div class="contact-add" v-if="fetched">
        <div class="vx-row mb-base">
            <div class="vx-col lg:w-1/2 w-full">
                <vx-card title="Kontakt Details" class="h-full">
                    <div class="vx-row">
                        <div class="vx-col lg:w-full w-full">
                            <div class="vx-row">
                                <div class="vx-col w-full sm:w-1/3 xl:w-1/5">
                                    <vs-select
                                            class="selectExample w-full"
                                            label="Anrede"
                                            name="salutation"
                                            v-model="clientData.salutation"
                                    >
                                        <vs-select-item :is-selected.sync="item.isSelected" :key="index"
                                                        :value="item.value" :text="item.label"
                                                        v-for="(item,index) in salutations"/>
                                    </vs-select>
                                </div>

                                <div class="vx-col w-full sm:w-1/3 xl:w-2/5 mt-4 sm:mt-0">
                                    <vs-input class="w-full" label="Vorname" data-vv-as="Vorname" name="first_name"
                                              v-validate="'required'"
                                              v-model="clientData.first_name" type="text"/>

                                    <span class="text-danger text-sm" v-if="errors.has('first_name')">{{ errors.first('first_name') }}</span>
                                </div>

                                <div class="vx-col w-full sm:w-1/3 xl:w-2/5 mt-4 sm:mt-0">
                                    <vs-input class="w-full" label="Nachname" data-vv-as="Nachname" name="last_name"
                                              v-validate="'required'"
                                              v-model="clientData.last_name" type="text"/>

                                    <span class="text-danger text-sm" v-if="errors.has('last_name')">{{ errors.first('last_name') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="vx-row mt-4">
                        <div class="vx-col w-full">
                            <vs-input class="w-full" label="E-Mail-Adresse" data-vv-as="E-Mail-Adresse" name="email"
                                      icon-pack="feather" icon="icon-mail" icon-no-border
                                      v-validate="'required|email'"
                                      v-model="clientData.email" type="text"/>

                            <span class="text-danger text-sm" v-if="errors.has('email')">{{ errors.first('email') }}</span>
                        </div>
                    </div>

                    <div class="vx-row mt-4">
                        <div class="vx-col w-full sm:w-1/2 mt-4 sm:mt-0">
                            <vs-input class="w-full" label="Telefon" data-vv-as="Telefon" name="phone"
                                      icon-pack="feather" icon="icon-phone" icon-no-border
                                      v-model="clientData.phone" type="text"/>
                        </div>

                        <div class="vx-col w-full sm:w-1/2 mt-4 sm:mt-0">
                            <vs-input class="w-full" label="Mobil" data-vv-as="Mobil" name="fax"
                                      icon-pack="feather" icon="icon-smartphone" icon-no-border
                                      v-model="clientData.mobile" type="text"/>
                        </div>
                    </div>

                    <div class="vx-row mt-4">
                        <div class="vx-col w-full">
                            <vs-input class="w-full" label="Textliche Anrede" name="letter_salutation"
                                      v-model="clientData.letter_salutation" type="text"/>
                        </div>
                    </div>

                    <div class="vx-row mt-4">
                        <div class="vx-col w-full">
                            <vs-textarea class="w-full mb-0" label="Interner Kommentar" name="internal_comment"
                                      v-model="clientData.internal_comment" type="text"/>
                        </div>

                    </div>

                </vx-card>
            </div>
            <div class="vx-col lg:w-1/2 w-full">
              <client-assignment v-model="clientData.clients" class="h-full"></client-assignment>
            </div>
        </div>

        <div class="flex mt-8 justify-end">
            <vs-button color="warning" type="border" class="mb-4" :to="{name :'contacts'}">Abbrechen</vs-button>
            <vs-button class="ml-3 mb-4" @click="saveContact">Hinzufügen</vs-button>
        </div>
    </div>
</template>

<script>
    import ApiService from "../../../api";
    import staticOptions from "../../../mixins/static/options";
    import ClientAssignment from "../clients/ClientAssignment";

    export default {
        components: {ClientAssignment},
        data() {
            return {
                fetched: false,
                salutations: staticOptions.salutations,
                clientData: {
                    salutation: 1,
                    first_name: null,
                    last_name: null,
                    phone: null,
                    mobile: null,
                    email: null,
                    internal_comment: null,
                    letter_salutation: null,
                    clients: []
                },
            }
        },
        mounted() {
            this.fetchInitialData();
        },
        methods: {
            fetchInitialData() {
                ApiService.get('clients?limit=1&order[field]=customer_number&order[direction]=desc').then((response) => {
                    this.fetched = true;
                })
            },
            saveContact() {
                this.$validator.validate().then(valid => {
                    if (valid) {
                        ApiService.post('contacts', this.clientData).then((response) => {
                            if (response.data.result) {
                                this.$router.push({name: 'contacts-detail', params: {id: response.data.result.id}})
                            }
                        }).catch((error) => {
                        });
                    }
                });
            }
        },
    }
</script>
